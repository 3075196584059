const message = [
  'С тобой все в порядке. Ты ценность. Что бы ни происходило, не вини и не ругай себя. Просто скажи себе: «Со мной все в порядке. Я ценность»',
  'Прислушайся к себе! Ты действительно этого хочешь? Если да, то смело иди вперед!',
  'С тобой все хорошо.  Даже если твой путь отличается от других. Даже если твои интересы отличаются от интересов других людей.  С тобой все в порядке.',
  'Не сравнивай себя с другими. Ты уникальная и у тебя свой путь!  Верь в себя и у тебя все получится.',
  'Отпусти прошлое. Пришло время жить в настоящем.',
  'Тебя ждет успех и процветание. Скажи себе: «У меня все получится, даже если в меня никто не верит». Нет ничего важнее твоей собственной уверенности в своих силах.',
  'Не дай страхам и сомнениям помешать тебе!',
  'Твоим  желаниям свойственно сбываться.',
  'Доверься своим идеям.  У тебя все получится. ',
  'Скажи себе: «Мое благосостояние растет с каждым днем». Повторяй это регулярно и увидишь, как твоя жизнь будет ежедневно меняться в лучшую сторону.',
  'Скажи себе: «Я ценю все то , что у меня есть,  а будет еще лучше.  Обязательно!  Я в это верю».',
  'Скажи себе: «Я готова принять изобилие в свою жизнь».  Повторяй это регулярно и увидишь, как твоя жизнь будет меняться в лучшую сторону с каждым днем.',
];

export default message;

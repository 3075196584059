const message = [
  'S7U8ExhCK50',
  'K5zJPyTmst4',
  'LgVPsYXCeUo',
  'kndqIj8Qgok',
  'hQv8ow4Y7tU',
  'Df3mkjtEwCY',
  'W6C-daO2hdM',
  'bmguzgSd0Uw',
  'Po2knguScG8',
  'QEiiuecS7ag',
  '1FBNIVQXhPc',
  'kzjNWdWu__s',
  'akBFTyfKTrA',
];

export default message;
